import scroll from "./scroll";
export default function closeOtherHeaderActions(currentAction) {
  if (!currentAction) return;
  var siteHeader = document.getElementsByClassName("js_site-header")[0];
  var searchForm = siteHeader.getElementsByClassName("js_search-form")[0];
  var megaMenu = siteHeader.getElementsByClassName("js_mega-menu")[0];
  var searchOverlay = document.getElementsByClassName("js_site-overlay")[0];
  if (currentAction !== "search" && !(searchForm !== null && searchForm !== void 0 && searchForm.classList.contains("search-form--closed"))) {
    searchForm === null || searchForm === void 0 || searchForm.classList.add("search-form--closed");
    var searchToggle = siteHeader.getElementsByClassName("js_search-toggle")[0];
    searchToggle === null || searchToggle === void 0 || searchToggle.classList.remove("search-toggle--active");
    searchOverlay === null || searchOverlay === void 0 || searchOverlay.classList.remove("hidden");
    if (currentAction !== "menu") scroll.enableScroll();
  }
  if (currentAction !== "menu" && !(megaMenu !== null && megaMenu !== void 0 && megaMenu.classList.contains("hidden"))) {
    megaMenu === null || megaMenu === void 0 || megaMenu.classList.add("hidden");
    var menuToggle = siteHeader.getElementsByClassName("js_menu-toggle")[0];
    menuToggle === null || menuToggle === void 0 || menuToggle.classList.remove("menu-toggle--active");
    searchOverlay === null || searchOverlay === void 0 || searchOverlay.classList.add("hidden");
    if (currentAction !== "search") scroll.enableScroll();
  }
}