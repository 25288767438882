import closeOtherHeaderActions from "../helpers/closeOtherHeaderActions";
import scroll from "../helpers/scroll";
export default function toggleMegaMenu() {
  var megaMenu = document.getElementsByClassName("js_mega-menu")[0];
  var menuToggle = document.getElementsByClassName("js_menu-toggle")[0];
  var overlay = document.getElementsByClassName("js_site-overlay")[0];
  if (!megaMenu || !menuToggle || !overlay) return;
  menuToggle.addEventListener("click", function () {
    var shouldShowMenu = megaMenu.classList.contains("hidden");
    if (shouldShowMenu) showMenu();else closeMenu();
  });
  overlay.addEventListener("click", function () {
    closeMenu();
  });
  function showMenu() {
    closeOtherHeaderActions("menu");
    scroll.disableScroll();
    overlay.classList.remove("hidden");
    megaMenu.classList.remove("hidden");
    menuToggle.classList.add("menu-toggle--active");
  }
  function closeMenu() {
    scroll.enableScroll();
    overlay.classList.add("hidden");
    megaMenu.classList.add("hidden");
    menuToggle.classList.remove("menu-toggle--active");
  }
}